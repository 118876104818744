<template>
  <div
    id="new-appointment-modal"
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <!-- Modal body -->

    <div
      class="z-40 relative top-12 px-2 md:px-6 py-8 h-vh mx-auto overflow-scroll scrollbar-none border w-4/5 md:w-3/5 shadow-lg rounded-md bg-white"
    >
      <button
        type="button"
        class="absolute top-0 right-0 my-3 mx-6 text-gray-400 bg-transparent p-1 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
        @click="closeNewPet"
      >
        <svg
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>

      <div
        id="new-client-title"
        class="mx-auto justify-center my-1 bg-theme-40 nunito rounded-md p-3 mt-5"
      >
        <h2 class="font-bold text-center text-xl text-white">
          {{ title }}
        </h2>
      </div>

      <div class="mx-auto">
        <form class="">
          <!-- header anagrafica -->
          <div class="">
            <div class="mt-5 mb-2 p-2 bg-theme-45 nunito rounded-md">
              <h2 class="ml-3 text-base font-bold">Anagrafica</h2>
            </div>
            <!-- first row -->

            <div
              class="mx-3 flex flex-col justify-between gap-3 md:flex-row flex-wrap"
            >
              <div class="flex-1 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newpet-nome"
                >
                  Nome
                </label>
                <input
                  id="newpet-nome"
                  v-model="petFormData['name']"
                  class="shadow appearance-none border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none focus:border-gray-100 placeholder-gray-400"
                  :class="{
                    notapproved: !inputcontrol.name
                  }"
                  type="text"
                  placeholder="Scrivi il nome"
                />
                <span v-if="!inputcontrol.name" class="text-theme-11 mt-1">
                  Questo è un campo obbligatorio</span
                >
              </div>

              <div class="flex-1 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newpet-chip"
                >
                  Microchip / Tatuaggio
                </label>
                <input
                  id="newpet-chip"
                  v-model="petFormData['microchip']"
                  class="shadow appearance-none border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none focus:border-gray-100 placeholder-gray-400"
                  type="text"
                  placeholder="Inserisci nr. microchip"
                />
              </div>

              <div class="py-2 flex-0 md:w-1/5">
                <label
                  for="newpet-genere"
                  class="block text-gray-700 text-xs font-bold mb-2"
                >
                  Genere</label
                >

                <v-select
                  id="newpet-genere"
                  v-model="petFormData['gender']"
                  placeholder="Genere"
                  :options="['F', 'M']"
                  :class="{
                    notapproved: !inputcontrol.gender
                  }"
                />
                <span v-if="!inputcontrol.gender" class="text-theme-11 mt-1">
                  Questo è un campo obbligatorio</span
                >
              </div>
              <div class="flex items-center mt-8 gap-3">
                <div class="flex gap-2 items-center">
                  <label
                    for="newpet-sterilizzato"
                    class="block text-gray-700 text-xs font-bold"
                  >
                    Sterilizzato
                  </label>

                  <input
                    id="newpet-sterilizzato"
                    v-model="petFormData['sterilized']"
                    style="box-shadow: none"
                    type="checkbox"
                    class="form-checkbox h-3 w-3 text-theme-41 rounded-sm text-sm focus:ring-0 focus:shadow-none"
                  />
                </div>
                <div class="flex gap-2 items-center">
                  <label
                    for="newpet-sterilizzato"
                    class="block text-gray-700 text-xs font-bold"
                  >
                    Donatore
                  </label>

                  <input
                    id="newpet-donor"
                    v-model="petFormData['donor']"
                    style="box-shadow: none"
                    type="checkbox"
                    class="form-checkbox h-3 w-3 text-theme-41 rounded-sm text-sm focus:ring-0 focus:shadow-none"
                  />
                </div>
              </div>
            </div>
            <!-- second row -->
          </div>
          <!-- end anagrafica -->

          <!-- header dettagli paziente -->
          <div class="">
            <div class="mt-5 mb-2 p-2 bg-theme-45 nunito rounded-md">
              <h2 class="ml-3 text-base font-bold">Dettagli paziente</h2>
            </div>

            <!-- first row -->

            <div class="mx-3 flex flex-col justify-between gap-3 md:flex-row">
              <div class="flex-1 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newpet-specie"
                >
                  Specie
                </label>

                <v-select
                  id="newpet-specie"
                  v-model="petFormData['type']"
                  :class="{
                    notapproved: !inputcontrol.pet_type_id
                  }"
                  placeholder="Seleziona la specie"
                  :options="petTypes"
                  label="name"
                />
                <span
                  v-if="!inputcontrol.pet_type_id"
                  class="text-theme-11 mt-1"
                >
                  Questo è un campo obbligatorio</span
                >
                <label
                  v-if="
                    pet &&
                    pet.import_data &&
                    pet.import_data.old_pet_type &&
                    petTypes &&
                    (!petFormData['type'] || petFormData['type'].id === 17)
                  "
                  class="block text-gray-700 text-xs"
                >
                  ({{ pet.import_data.old_pet_type }})
                </label>
              </div>
              <div v-if="breeds != null" class="flex-1 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newpet-razza"
                >
                  Razza
                </label>

                <v-select
                  id="newpet-razza"
                  v-model="petFormData['breed']"
                  class="shadow border border-gray-300 rounded w-full px-3 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none focus:border-gray-100 placeholder-gray-400"
                  :class="{
                    notapproved: !inputcontrol.pet_breed_id
                  }"
                  placeholder="Seleziona la razza"
                  :options="breeds"
                  label="name"
                />
                <span
                  v-if="!inputcontrol.pet_breed_id"
                  class="text-theme-11 mt-1"
                >
                  Questo è un campo obbligatorio</span
                >
                <label
                  v-if="
                    pet &&
                    pet.import_data &&
                    pet.import_data.old_breed &&
                    !petFormData['breed']
                  "
                  class="block text-gray-700 text-xs"
                >
                  ({{ pet.import_data.old_breed }})
                </label>
              </div>

              <div class="flex-1 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newpet-color"
                >
                  Colore
                </label>
                <v-select
                  id="newpet-color"
                  v-model="petFormData['color']"
                  placeholder="Seleziona il colore"
                  label="name"
                  :reduce="(value) => value.name"
                  :options="petColors"
                >
                  <template #option="option">
                    {{ option.name }}
                  </template>
                </v-select>
              </div>
              <div class="flex-1 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newpet-hair-type"
                >
                  Pelo
                </label>

                <v-select
                  id="newpet-hair-type"
                  v-model="petFormData['hair_type']"
                  placeholder="Seleziona il pelo"
                  :options="['Raso', 'Corto', 'Medio', 'Lungo', 'Tipico']"
                />
              </div>
            </div>

            <!-- end first row -->
            <!-- second row -->

            <div class="mx-3 flex flex-col justify-between gap-3 md:flex-row">
              <div class="flex-0 py-2">
                <label
                  for="newpet-nascita"
                  class="block text-gray-700 text-xs font-bold mb-2"
                  >Data di nascita</label
                >

                <div class="relative">
                  <input
                    id="newpet-nascita"
                    :value="petFormData['date_of_birth']"
                    type="date"
                    class="shadow appearance-none border border-gray-300 rounded w-full py-1.5 pr-10 pl-3 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none focus:border-gray-100 placeholder-gray-400"
                    :class="{
                      notapproved: !inputcontrol.birth_date
                    }"
                    placeholder="Seleziona data di nascita"
                    @change="(el) => changeValue(el)"
                  />
                  <span
                    v-if="!inputcontrol.birth_date"
                    class="text-theme-11 mt-1"
                  >
                    Questo è un campo obbligatorio</span
                  >
                  <!-- <div
                    class="flex absolute inset-y-0 left-44 pl-3 items-center pointer-events-none"
                  >
                    <svg
                      class="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div> -->
                </div>
              </div>

              <div class="flex-0 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newpet-eta"
                >
                  Età (Anni)
                </label>
                <input
                  id="newpet-eta"
                  v-model="petYears"
                  class="custom-input"
                  placeholder="Età"
                  disabled
                />
              </div>
              <div class="flex-0 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newpet-eta"
                >
                  Età (mesi)
                </label>
                <input
                  id="newpet-eta"
                  v-model="petMonths"
                  class="custom-input"
                  placeholder="Età"
                  disabled
                />
              </div>

              <div class="flex-1 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newpet-eta"
                >
                  Gruppo sanguigno
                </label>

                <v-select
                  id="newpet-eta"
                  v-model="petFormData['blood_group']"
                  :class="{
                    notapproved: !inputcontrol.blood_group
                  }"
                  placeholder="Seleziona il gruppo sanguigno"
                  :reduce="(value) => value.blood_group"
                  label="blood_group"
                  :options="bloods"
                >
                  <template #option="option">
                    {{ option.blood_group }}
                  </template>
                </v-select>
                <span
                  v-if="!inputcontrol.blood_group"
                  class="text-theme-11 mt-1"
                >
                  Questo è un campo obbligatorio</span
                >
              </div>
            </div>
            <!-- end second row -->

            <!-- third row -->

            <div class="mx-3 mt-3">
              <div class="flex-1 py-2 flex">
                <label class="block text-gray-700 text-xs font-bold mb-2"
                  >Animale deceduto</label
                >
                <input
                  v-model="petDeadRadio"
                  type="checkbox"
                  class="form-checkbox ml-2 h-4 w-4 text-theme-41 rounded-sm text-sm focus:ring-0 focus:shadow-none"
                />
              </div>
            </div>

            <div
              v-show="petDeadRadio"
              class="mx-3 flex flex-col flex-wrap justify-between gap-3 md:flex-row"
            >
              <div
                class="w-full flex flex-col flex-wrap justify-between gap-3 md:flex-row"
              >
                <div class="flex-0 py-2">
                  <label
                    for="newpet-decesso"
                    class="block text-gray-700 text-xs font-bold mb-2"
                    >Data di decesso</label
                  >

                  <div class="relative">
                    <input
                      id="newpet-decesso"
                      :value="petFormData['date_of_death']"
                      type="date"
                      class="shadow appearance-none border border-gray-300 rounded w-full py-1.5 pr-10 pl-3 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none focus:border-gray-100 placeholder-gray-400"
                      :class="{
                        notapproved: !inputcontrol.date_of_death
                      }"
                      placeholder="Seleziona data di decesso"
                      @change="(el) => changeValue(el)"
                    />
                    <span
                      v-if="!inputcontrol.date_of_death"
                      class="text-theme-11 mt-1"
                    >
                      Questo è un campo obbligatorio</span
                    >
                    <div
                      class="flex absolute inset-y-0 left-44 pl-3 items-center pointer-events-none"
                    >
                      <svg
                        class="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>

                <div class="flex-1 py-2">
                  <label
                    class="block text-gray-700 text-xs font-bold mb-2"
                    for="newpet-death-surgery"
                  >
                    Decesso per intervento chirurgico
                  </label>

                  <v-select
                    id="newpet-death-surgery"
                    v-model="petFormData['death_surgery']"
                    class="shadow border border-gray-300 rounded w-full px-3 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none focus:border-gray-100 placeholder-gray-400"
                    placeholder="Seleziona"
                    :reduce="(value) => value.value"
                    :options="[
                      { label: 'Sì', value: true },
                      { label: 'No', value: false }
                    ]"
                  />
                </div>
              </div>

              <div class="flex-1 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newpet-death-notes"
                >
                  Motivo decesso:
                </label>

                <textarea
                  id="newpet-death-notes"
                  v-model="petFormData['death_notes']"
                  class="shadow appearance-none border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none focus:border-gray-100 placeholder-gray-400"
                  type="text"
                  placeholder="Inserisci la motivazione del decesso"
                />
              </div>
            </div>
            <!-- end third row -->
          </div>
          <!-- end dettagli paziente -->

          <!-- header altri dettagli -->
          <div class="">
            <div class="mt-5 mb-2 p-2 bg-theme-45 nunito rounded-md">
              <h2 class="ml-3 text-base font-bold">Altri dettagli</h2>
            </div>

            <!-- first row -->

            <div
              v-if="false"
              class="mx-3 flex flex-col justify-between gap-3 md:flex-row"
            >
              <div class="flex-1 py-2 flex">
                <label class="block text-gray-700 text-xs font-bold mb-2"
                  >Residenza diversa al cliente?</label
                >
                <input
                  v-model="differentResidency"
                  type="checkbox"
                  class="form-checkbox ml-2 h-4 w-4 text-theme-15 rounded-sm text-sm focus:ring-0 focus:shadow-none"
                />
              </div>
            </div>

            <div class="mx-3 flex flex-col justify-between gap-3 md:flex-row">
              <div class="flex-1 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newpet-indirizzo"
                >
                  Luogo in cui risiede
                </label>
                <!-- <Places
                  @place_changed="residencyChanged"
                  :addressPlaceholderProps="addressLabel"
                /> -->
                <v-select
                  v-model="petFormData['address']"
                  placeholder="Luogo in cui risiede l'animale"
                  :options="['Indoor', 'Outdoor', 'Roaming']"
                />
                <!-- <input
                  id="newpet-indirizzo"
                  v-model="petFormData['address']"
                  class="shadow appearance-none border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none focus:border-gray-100 placeholder-gray-400"
                  :class="{
                    notapproved: !inputcontrol.address
                  }"
                  type="text"
                  placeholder="Inserisci l'indirizzo"
                /> -->
                <span v-if="!inputcontrol.address" class="text-theme-11 mt-1">
                  Questo è un campo obbligatorio</span
                >
              </div>
              <div class="flex-0 py-2">
                <label
                  for="newpet-adozione"
                  class="block text-gray-700 text-xs font-bold mb-2"
                  >Data di acquisizione</label
                >

                <div class="relative">
                  <input
                    id="newpet-adozione"
                    v-model="petFormData['adoption_date']"
                    type="date"
                    class="shadow appearance-none border border-gray-300 rounded w-full py-1.5 pr-10 pl-3 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none focus:border-gray-100 placeholder-gray-400"
                    :class="{
                      notapproved: !inputcontrol.adoption_date
                    }"
                    placeholder="Seleziona la data di acquisizione"
                  />
                  <span
                    v-if="!inputcontrol.adoption_date"
                    class="text-theme-11 mt-1"
                  >
                    Questo è un campo obbligatorio</span
                  >
                  <!-- <div
                    class="flex absolute inset-y-0 left-44 pl-3 items-center pointer-events-none"
                  >
                    <svg
                      class="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div> -->
                </div>
              </div>
            </div>

            <!-- end first row -->
          </div>
          <!-- end altri dettagli -->

          <!-- header Dettagli medici -->
          <div class="">
            <div class="mt-5 mb-2 p-2 bg-theme-45 nunito rounded-md">
              <h2 class="ml-3 text-base font-bold">Dettagli medici</h2>
            </div>

            <!-- first row -->

            <div class="mx-3 flex flex-col justify-between gap-3 md:flex-row">
              <div class="flex-1 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newpet-medico"
                >
                  Medico curante
                </label>

                <v-select
                  id="newpet-medico"
                  v-model="petFormData['int_staff_user_id']"
                  :class="{
                    notapproved: !inputcontrol.int_staff_user_id
                  }"
                  placeholder="Seleziona il medico curante"
                  label="last_name"
                  :options="staff"
                >
                  <template #selected-option="option">
                    {{ option.first_name }} {{ option.last_name }}
                  </template>
                  <template #option="option">
                    {{ option.first_name }} {{ option.last_name }}
                  </template>
                </v-select>
                <span
                  v-if="!inputcontrol.int_staff_user_id"
                  class="text-theme-11 mt-1"
                >
                  Questo è un campo obbligatorio</span
                >
              </div>

              <div class="flex-1 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newpet-refesterno"
                >
                  Referente esterno
                </label>

                <v-select
                  id="newpet-refesterno"
                  v-model="petFormData['external_reference_id']"
                  placeholder="Seleziona il referente esterno"
                  label="last_name"
                  :options="externals"
                >
                  <template #selected-option="option">
                    {{ option.first_name }} {{ option.last_name }}
                  </template>
                  <template #option="option">
                    {{ option.first_name }} {{ option.last_name }}
                  </template></v-select
                >
              </div>

              <div class="flex mt-3 items-baseline">
                <div class="flex-0 pt-6">
                  <button
                    type="button"
                    class="orange-btn-inverted"
                    @click="openReferente"
                  >
                    <UserPlusIcon class="w-4 h-4 mr-2.5" />

                    Nuovo referente esterno
                  </button>
                </div>
              </div>
            </div>

            <!-- end first row -->

            <!-- second row -->

            <!-- end second row -->
          </div>
          <!-- end Dettagli medici -->

          <!-- header altro -->
          <div class="">
            <div class="mt-5 mb-2 p-2 bg-theme-45 nunito rounded-md">
              <h2 class="ml-3 text-base font-bold">Altro</h2>
            </div>
            <!-- first row -->

            <div class="mx-3 flex flex-col justify-between gap-3 md:flex-row">
              <div class="flex-1 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newpet-parolechiave"
                >
                  Parole chiave
                </label>
                <input
                  v-model="petFormData['keywords']"
                  class="shadow appearance-none border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none focus:border-gray-100 placeholder-gray-400"
                  type="text"
                  placeholder="Inserisci le parole chiave"
                />
              </div>
            </div>
            <div class="mx-3 flex flex-col justify-between gap-3 md:flex-row">
              <div class="flex-1 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newclient-note"
                >
                  Note
                </label>

                <textarea
                  id="newclient-note"
                  v-model="petFormData['notes']"
                  class="shadow appearance-none border border-gray-300 rounded w-full py-1.5 px-3 h-20 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none focus:border-gray-100 placeholder-gray-400"
                  type="text"
                  placeholder="Inserisci le note"
                />
              </div>
            </div>

            <!-- end first row -->
          </div>
          <!-- end altro -->

          <!-- footer -->

          <div class="mt-5 flex flex-col justify-between md:flex-row">
            <div>
              <div v-if="!comingFromClient && showGoPatientButton">
                <button
                  v-if="pet == null"
                  type="button"
                  class="orange-btn"
                  @click="backToClient"
                >
                  <SkipBackIcon class="w-4 h-4 mr-2" /> Aggiungi Cliente
                </button>
              </div>
            </div>
            <div class="flex gap-2">
              <button type="button" class="orange-btn" @click="saveClientForm">
                <SaveIcon class="w-5 h-5 mr-2" />
                {{ buttonText }}
              </button>
              <button type="button" class="gray-btn" @click="closeNewPet">
                <span class="h-5"></span>
                Annulla
              </button>
            </div>
          </div>

          <!--  end footer -->
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import flatpickr from 'flatpickr'
import { Italian } from 'flatpickr/dist/l10n/it.js'
import axios from '../../gateway/backend-api'

//import Places from '../../components/places/Places.vue'

export default defineComponent({
  components: {
    //Places
  },
  props: {
    title: String,
    pet: Object,
    client: Object,
    comingFromClient: Object
  },
  emits: ['render', 'edit_close', 'referente', 'back', 'close'],
  data() {
    return {
      addressLabel: '',
      differentResidency: false,
      petDeadRadio: false,
      newReferent: false,
      bloods: [],
      pet_breed: [],
      pet_type: [],
      petFormData: {
        id: ''
      },
      petTypes: [],
      petColors: [],
      allColors: null,
      staff: [],
      externals: [],
      inputcontrol: {
        name: true,
        pet_type_id: true,
        pet_breed_id: true,
        gender: true,
        birth_date: true,
        date_of_death: true,
        blood_group: true,
        address: true,
        adoption_date: true,
        int_staff_user_id: true
      },
      petYears: 0,
      petMonths: 0,
      petDays: 0,
      dataTest: null,
      showGoPatientButton: true
    }
  },
  watch: {
    // staff(element) {
    //   if (this.pet.staff_user_id && element) {
    //     console.log('sono nell if ')
    //     element.forEach((elem) => {
    //       if (elem.id === this.pet.staff_user_id) {
    //         this.petFormData['int_staff_user_id'] = elem
    //       }
    //     })
    //   }
    // },
    'petFormData.type'(elem, prevEl) {
      this.petColors = []
      if (elem != null) {
        if (prevEl !== null && prevEl !== undefined) {
          this.petFormData['breed'] = null
          this.petFormData['color'] = null
        }
        this.breeds = elem.breeds
        if (this.pet) {
          if (elem.id !== this.pet.pet_type_id) {
            this.petFormData['breed'] = null
            this.petFormData['color'] = null
          }
        }
        this.petFormData['blood_group'] = null
        let payload = {}
        payload['pet_type_id'] = elem.id
        this.getBloodGroups(payload)

        if (this.allColors)
          Object.keys(this.allColors).forEach((el) => {
            if (this.allColors[el].name === elem.name)
              this.petColors = this.allColors[el].color
          })
      } else {
        this.petFormData['breed'] = null
        this.petFormData['color'] = null
        this.petFormData['blood_group'] = null
        this.bloods = []
      }
    },
    petDeadRadio(elem) {
      this.petFormData['alive'] = !elem
    }
  },
  created() {
    axios
      .get('/' + localStorage.getItem('clinic') + '/pet/types_breeds')
      .then((res) => {
        this.petTypes = res.data
        if (this.pet != null) {
          this.petTypes.find((type) => {
            if (type.id == this.pet.pet_type_id) {
              this.petFormData['type'] = type
              type.breeds.find((breed) => {
                if (breed.id == this.pet.pet_breed_id) {
                  this.petFormData['breed'] = breed
                }
              })
            }
          })
        }
      })

    axios.post('/' + localStorage.getItem('clinic') + '/staff').then((res) => {
      this.staff = res.data
      if (this.staff && this.pet && this.pet.staff_user_id)
        this.staff.find((vet) => {
          if (vet.id == this.pet.staff_user_id) {
            this.petFormData['int_staff_user_id'] = vet
          }
        })
    })

    axios
      .post('/' + localStorage.getItem('clinic') + '/external/index', {
        type: 'referent',
        per_page: 500
      })
      .then((res) => {
        this.externals = res.data.data
        if (this.externals && this.pet && this.pet.externals)
          this.externals.find((ext) => {
            if (ext?.id == this.pet.externals?.id) {
              this.petFormData['external_reference_id'] = ext
            }
          })
      })

    // API che richiede i gruppi sanguigni
    let payload = {}
    if (this.pet && this.pet.pet_type_id) {
      payload['pet_type_id'] = this.pet.pet_type_id
      this.getBloodGroups(payload)
    }

    // API che richiede i colori
    payload = {}
    if (this.pet && this.pet.pet_type_id) {
      payload['pet_type_id'] = this.pet.pet_type_id
    }
    axios
      .post('/' + localStorage.getItem('clinic') + '/pet/pet-colors', payload)
      .then((res) => {
        this.allColors = res.data
        if (this.pet && this.pet.pet_type_id)
          this.petColors = res.data[this.pet.pet_type_id].color.map(function (
            row
          ) {
            return {
              id: row.id,
              key: row.id,
              name: row.name,
              color: row.name
            }
          })

        if (this.pet != null) {
          this.petColors.find((type) => {
            if (type.color == this.pet.color) {
              this.petFormData['color'] = type.color
            }
          })
        }
      })

    if (this.pet != null) {
      if (this.pet.id && this.pet.id !== '') {
        this.buttonText = 'Salva modifiche'
        if (this.pet.pet_info) {
          let birthDate = this.pet.pet_info.birth_date
          this.petYears = this.$getDifferenceYears(birthDate)
          let totalMonths = this.$getDifferenceMonths(birthDate)
          this.petMonths = totalMonths - this.petYears * 12
        }

        this.petFormData['name'] = this.pet.name
        this.petFormData['microchip'] = this.pet.microchip
        this.petFormData['gender'] = this.pet.gender
        this.petFormData['hair_type'] = this.pet.hair_type
        this.petFormData['color'] = this.pet.color
        this.petFormData['death_surgery'] = this.pet.death_surgery
        this.petFormData['death_notes'] = this.pet.death_notes
        this.petFormData['notes'] = this.pet.notes
        this.petFormData['keywords'] = this.pet.keywords
        //
        if (this.pet.pet_info)
          this.petFormData['blood_group'] = this.pet.pet_info.blood_group
        this.petFormData['sterilized'] = this.pet.sterilized
        this.petFormData['donor'] = this.pet.donor
        //
        if (this.pet.pet_info && this.pet.pet_info.birth_date) {
          this.petFormData['date_of_birth'] = this.$FormatDateEnglish(
            this.pet.pet_info.birth_date
          )
        }

        //
        if (!this.pet.alive) {
          this.petFormData['alive'] = false
          this.petDeadRadio = true
        }
        //
        if (this.pet.date_of_death != null)
          this.petFormData['date_of_death'] = this.$FormatDateEnglish(
            this.pet.date_of_death
          )
        //
        if (this.pet.pet_info && this.pet.pet_info.adoption_date != null)
          this.petFormData['adoption_date'] = this.$FormatDateEnglish(
            this.pet.pet_info.adoption_date
          )
        //
        if (this.pet.pet_info && this.pet.pet_info.address) {
          this.petFormData['address'] = this.pet.pet_info.address
          this.addressLabel = this.pet.pet_info.address
        }
      } else {
        this.buttonText = 'Salva'
        if (this.pet.date_of_birth) {
          this.petYears = this.$getDifferenceYears(this.pet.date_of_birth)
          //this.petMonths = this.$getDifferenceMonths(this.pet.date_of_birth)
          this.petFormData['date_of_birth'] = this.$FormatDateEnglish(
            this.pet.date_of_birth
          )
        }

        this.petFormData['name'] = this.pet.name
        this.petFormData['microchip'] = this.pet.microchip
        this.petFormData['gender'] = this.pet.gender
        this.petFormData['hair_type'] = this.pet.hair_type
        this.petFormData['color'] = this.pet.color
        this.petFormData['death_surgery'] = this.pet.death_surgery
        this.petFormData['death_notes'] = this.pet.death_notes
        //

        this.petFormData['blood_group'] = this.pet.blood_group
        this.petFormData['sterilized'] = this.pet.sterilized
        this.petFormData['donor'] = this.pet.donor
        this.petFormData['notes'] = this.pet.notes
        this.petFormData['keywords'] = this.pet.keywords

        if (this.pet.staff_user_id && this.staff) {
          this.staff.forEach((elem) => {
            if (elem.id === this.pet.staff_user_id) {
              this.petFormData['int_staff_user_id'] = elem
            }
          })
        }

        //

        //
        if (this.pet.alive && this.pet.alive === false) {
          this.petFormData['alive'] = false
          this.petDeadRadio = true
        } else {
          this.petFormData['alive'] = true
          this.petDeadRadio = false
        }
        //
        if (this.pet.date_of_death != null)
          this.petFormData['date_of_death'] = this.$FormatDateEnglish(
            this.pet.date_of_death
          )
        //
        if (this.pet.adoption_date)
          this.petFormData['adoption_date'] = this.$FormatDateEnglish(
            this.pet.adoption_date
          )
        //
        if (this.pet && this.pet.address) {
          this.petFormData['address'] = this.pet.address
          this.addressLabel = this.pet.address
        }
        // --------------- fine else ---------------
      }
    } else {
      this.buttonText = 'Salva'
    }
  },

  mounted() {
    flatpickr.localize(Italian)
    flatpickr('.date', {
      maxDate: 'today',
      dateFormat: 'd/m/Y',
      allowInput: true,
      allowInvalidPreload: false
    })
  },
  methods: {
    getReferenti: function () {
      axios
        .post('/' + localStorage.getItem('clinic') + '/external/index', {
          type: 'referent',
          per_page: 500
        })
        .then((res) => {
          this.externals = res.data.data
          if (this.externals && this.pet && this.pet.externals)
            this.externals.find((ext) => {
              if (ext?.id == this.pet.externals?.id) {
                this.petFormData['external_reference_id'] = ext
              }
            })
        })
    },
    residencyChanged: function (elem) {
      this.petFormData.address = ''
      for (let i = 0; i < elem.length; i++) {
        this.petFormData.address += elem[i].long_name + ' '
      }
    },
    getBloodGroups: function (payload) {
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/pet-blood-groups',
          payload
        )
        .then((res) => {
          this.bloods = res.data
          if (this.pet != null) {
            this.bloods.find((type) => {
              if (type.blood_group == this.pet.pet_info.blood_group) {
                this.petFormData['blood_group'] = type.blood_group
              }
            })
          }
        })
    },
    changeValue(elem) {
      if (elem.target.id == 'newpet-decesso')
        this.petFormData['date_of_death'] = elem.target.value
      else {
        this.petFormData['date_of_birth'] = elem.target.value
        let dateInEpoch = this.$FormatEpochEnglish(elem.target.value)
        this.petYears = this.$getDifferenceYears(dateInEpoch)
        this.petMonths =
          this.$getDifferenceMonths(dateInEpoch) - this.petYears * 12
      }
    },
    openReferente() {
      this.$emit('referente')
    },
    createNewPet() {
      let newPet = this.petFormData
      if (this.petFormData.sterilized == null) {
        newPet.sterilized = false
      }
      if (this.petFormData.donor == null) {
        newPet.donor = false
      }
      if (this.petFormData.type) {
        newPet.pet_type_id = this.petFormData.type.id
      }
      if (this.petFormData.breed) {
        newPet.pet_breed_id = this.petFormData.breed
          ? this.petFormData.breed.id
          : null
      }

      if (this.petFormData.int_staff_user_id)
        newPet.int_staff_user_id = this.petFormData.int_staff_user_id
          ? this.petFormData.int_staff_user_id.id
          : null

      delete newPet.breed
      delete newPet.type
      let dateInEpoch
      if (this.petFormData['date_of_birth'] != null) {
        dateInEpoch = this.$FormatEpochEnglish(
          this.petFormData['date_of_birth']
        )
        newPet.date_of_birth = dateInEpoch
      }
      if (this.petFormData['adoption_date'] != null) {
        dateInEpoch = this.$FormatEpochEnglish(
          this.petFormData['adoption_date']
        )
        newPet.adoption_date = dateInEpoch
      } else newPet.adoption_date = null

      if (this.petDeadRadio && this.petFormData['date_of_death'] != null) {
        dateInEpoch = this.$FormatEpochEnglish(
          this.petFormData['date_of_death']
        )
        newPet.date_of_death = dateInEpoch
      } else newPet.date_of_death = null

      return newPet
    },
    async saveClientForm() {
      if (this.formValidation()) {
        let newPet = this.createNewPet()

        if (this.pet != null && this.pet.id && this.pet.id !== '') {
          //E' un pet da modificare
          newPet.id = this.pet.id
          axios
            .post(
              '/' +
                localStorage.getItem('clinic') +
                '/pet/' +
                newPet.id +
                '/edit',
              newPet
            )
            .then((res) => {
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: 'Paziente modificato con successo!'
              })
              if (res.status == 200) this.$emit('edit_close')
            })
        } else {
          // E' un nuovo pet
          // Se il paziente non è aperto in modalità modifica non scrivo l'id nel JSON
          if (this.client?.id == null) {
            let patientInserted = true
            // Devi salvare sia il cliente passato nei parametri che il paziente, quindi salviamo prima il cliente
            await axios
              .post(
                '/' + localStorage.getItem('clinic') + '/customer',
                this.client
              )
              .then((res) => {
                this.$store.dispatch('commonComponents/openNotificationModal', {
                  message: 'Paziente inserito con successo!'
                })
                newPet.customer_id = res.data.id
              })
              .catch((err) => {
                this.$store.dispatch('commonComponents/openMessageModal', {
                  title: 'Errore',
                  subtitle: err.response.data.message
                })
                patientInserted = false
              })
            if (!patientInserted) {
              return
            }
          } else {
            newPet.customer_id = this.client.id
          }
          delete newPet.id
          axios
            .post('/' + localStorage.getItem('clinic') + '/pet', newPet)
            .then((res) => {
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: 'Paziente inserito con successo!'
              })

              if (this.comingFromClient) {
                this.$emit('render')
              } else {
                this.handleShowPdfPrivacy({
                  ...this.client,
                  id: this.client?.id ?? newPet.customer_id
                })
              }
              if (res.status == 200) this.closeNewPet()
            })
            .catch((err) => {
              this.$store.dispatch('commonComponents/openMessageModal', {
                title: 'Errore',
                subtitle: err.response.data.message
              })
              this.showGoPatientButton = false
            })
        }
      } else {
        alert('Ci sono dei campi obbligatori da riempire!')
      }
    },
    backToClient() {
      this.$emit('back', this.client, this.createNewPet())
    },
    closeNewPet() {
      this.$emit('close')
    },
    formValidation() {
      // Bisogna controllare i campi obbligatori
      let var_control = true
      if (this.petFormData.name == null || this.petFormData.name == '') {
        this.inputcontrol.name = false
        var_control = false
      } else {
        this.inputcontrol.name = true
      }
      // if (
      //   this.petFormData.date_of_birth == null ||
      //   this.petFormData.date_of_birth == ''
      // ) {
      //   this.inputcontrol.birth_date = false
      //   var_control = false
      // } else {
      //   this.inputcontrol.birth_date = true
      // }
      if (
        this.petDeadRadio &&
        (this.petFormData.date_of_death == null ||
          this.petFormData.date_of_death == '')
      ) {
        this.inputcontrol.date_of_death = false
        var_control = false
      } else {
        this.inputcontrol.date_of_death = true
      }
      if (
        !this.petFormData.type ||
        this.petFormData.type.id == null ||
        this.petFormData.type.id == ''
      ) {
        this.inputcontrol.pet_type_id = false
        var_control = false
      } else {
        this.inputcontrol.pet_type_id = true
      }

      if (this.differentResidency == true) {
        if (
          this.petFormData.address == null ||
          this.petFormData.address == ''
        ) {
          this.inputcontrol.address = false
          var_control = false
        } else {
          this.inputcontrol.address = true
        }
      }
      if (
        this.petFormData.type &&
        this.petFormData.breed &&
        this.petFormData.type.id != this.petFormData.breed.pet_type_id
      ) {
        var_control = false
        this.inputcontrol.pet_type_id = false
        this.inputcontrol.pet_breed_id = false
      }
      return var_control
    },
    openMessageModal(title, msg) {
      this.$store.dispatch('commonComponents/openMessageModal', {
        title: title,
        subtitle: msg
      })
    },
    handleShowPdfPrivacy(customer) {
      let data_to_send = {}
      if (customer.privacy) {
        data_to_send['privacy_id'] = customer.privacy.id
      }
      axios({
        url:
          '/' +
          localStorage.getItem('clinic') +
          '/customer/' +
          customer.id +
          '/privacy/pdf',
        method: 'POST',
        data: data_to_send
      }).then(async (response) => {
        this.$store.dispatch('commonComponents/hideLoadingModalAction')
        const base64Response = await fetch(
          `data:application/pdf;base64,${response.data.pdf}`
        )

        const url = window.URL.createObjectURL(
          new Blob([await base64Response.blob()])
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          'Privacy_' + customer.last_name + '_' + customer.first_name + '.pdf'
        )
        document.body.appendChild(link)
        this.$store.dispatch('commonComponents/openPdfModal', {
          title: customer.ragione_sociale
            ? 'Privacy_' + customer.ragione_sociale.replace(/\s/g, '_')
            : 'Privacy_' + customer.last_name + '_' + customer.first_name,
          link: url,
          background: false,
          print: () => {
            console.log('PRINT')
          },
          download: () => {
            link.click()
          },
          xml: null,
          table_reference: 'privacy',
          id_reference: response.data.privacy_id,
          pdf_signed: customer.privacy && customer.privacy.status === 'signed',
          client: customer,
          sign_done: () => {
            this.$emit('edit_close')
          }
        })
      })
    }
  }
})
</script>

<style scoped>
.notapproved {
  border: 1px solid #ff8f1c;
}
</style>
